var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_c('h3',{staticClass:"mb-2 mt-5"},[_vm._v(" "+_vm._s(_vm.$getVisibleNames( "mesh.egressprofiletype", true, "Tipos de Perfiles de Egreso" ))+" "),_c('AlternativeNameButton',{attrs:{"table_name":'mesh.egressprofiletype',"allows_crud":_vm.allows_crud}})],1),_c('div',{staticStyle:{"display":"flex"}},[_c('div',{staticClass:"col",staticStyle:{"text-align":"left"}},[(_vm.allows_crud)?_c('ButtonMedium',{directives:[{name:"can",rawName:"v-can",value:('mesh.add_egressprofiletype'),expression:"'mesh.add_egressprofiletype'"}],attrs:{"tooltip_text":`Agregar ${_vm.$getVisibleNames(
            'mesh.egressprofiletype',
            false,
            'Tipo de Perfil de Egreso'
          )}`,"text_button":`Agregar`,"click_button":() => _vm.$bvModal.show(`new-egress-profile-type-modal`),"icon":'plus',"variant":'primary'}}):_vm._e()],1)]),(_vm.searchEgressProfileType.length > 0)?_c('GenericBTable',{staticClass:"egress-profile-type-table",attrs:{"items":_vm.egress_profile_types,"pagination":_vm.egress_profile_types.length,"fields":_vm.egressProfileTypesFields,"filter":_vm.input_search,"show_pagination":true,"search_filter":true,"columns_display":true,"allows_crud":_vm.allows_crud,"display_id":_vm.display_id,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([(_vm.allows_crud)?{key:"cell(actions)",fn:function(row){return [_c('ButtonSmall',{class:`mr-1`,attrs:{"tooltip_text":`Editar ${_vm.$getVisibleNames(
            'mesh.egressprofiletype',
            false,
            'Tipo de Perfil de Egreso'
          )}`,"click_button":() =>
              _vm.$bvModal.show(`edit-egress-profile-type-modal-${row.item.id}`),"icon":'square'}}),(_vm.isValidDelete(row.item.id))?_c('ButtonSmall',{attrs:{"click_button":() => _vm.askForDeleteEgressProfileType(row.item.id),"tooltip_text":`Eliminar ${_vm.$getVisibleNames(
            'mesh.egressprofiletype',
            false,
            'Tipo de Perfil de Egreso'
          )}`,"icon":'trash'}}):_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.auto.noninteractive",value:(
            `No se puede eliminar la ${_vm.$getVisibleNames(
              'mesh.egressprofiletype',
              false,
              'Tipo de Perfil de Egreso'
            )} si cuenta con ${_vm.$getVisibleNames(
              'mesh.career',
              true,
              'Programas'
            )}`
          ),expression:"\n            `No se puede eliminar la ${$getVisibleNames(\n              'mesh.egressprofiletype',\n              false,\n              'Tipo de Perfil de Egreso'\n            )} si cuenta con ${$getVisibleNames(\n              'mesh.career',\n              true,\n              'Programas'\n            )}`\n          ",modifiers:{"v-secondary":true,"auto":true,"noninteractive":true}}]},[_c('ButtonSmall',{attrs:{"tooltip_text":``,"disabled_button":true,"icon":'trash'}})],1),_c('b-modal',{attrs:{"id":`edit-egress-profile-type-modal-${row.item.id}`,"title":`Editar ${_vm.$getVisibleNames(
            'mesh.egressprofiletype',
            false,
            'Tipo de Perfil de Egreso'
          )}`,"size":"md","hide-footer":""}},[_c('EgressProfileTypeForm',{attrs:{"EgressProfileType":row.item,"show_title":false},on:{"updated":_vm.slotUpdatedEgressProfileType}})],1)]}}:null],null,true)}):_c('div',[_c('br'),_c('strong',[_vm._v("No se a encontrado ningún "+_vm._s(_vm.$getVisibleNames( "mesh.egressprofiletype", false, "Tipo de Perfil de Egreso" ))+".")])])],1),_c('b-modal',{attrs:{"id":`new-egress-profile-type-modal`,"hide-footer":true,"title":`Crear ${_vm.$getVisibleNames(
      'mesh.egressprofiletype',
      false,
      'Tipo de Perfil de Egreso'
    )}`,"size":"md"}},[(_vm.institution)?_c('EgressProfileTypeForm',{attrs:{"default_module_minutes":_vm.institution.module_minutes,"show_title":false},on:{"created":_vm.slotCreatedEgressProfileType}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }